
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { getLedgerAll } from "@/api/request/item";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { getSignAwardSetList, saveSignAwardSet, getGiftAllList } from "@/api/request/user";

//组件
@Component({
  name: "signAwardSet",
})

//函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private itemList: any[] = [];
  private giftList: any[] = [];
  private isCreate: boolean = false;
  private listLoading: boolean = false;

  //创建
  created() {
    //获取道具列表
    this.getItemList();

    //获取礼包列表
    this.getGiftList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getSignAwardSetList();

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具列表
  private async getItemList() {
    //显示等待
    this.listLoading = true;

    //获取道具数据
    const res = await getLedgerAll();

    //数据赋值
    this.itemList = res.data;
    this.itemList.unshift({ propid: 0, propname: "请选择道具" });
  }

  //获取礼包列表
  private async getGiftList() {
    this.listLoading = true;

    //获取数据
    const { data } = await getGiftAllList();

    //数据赋值
    this.giftList = data;
    this.giftList.unshift({ id: 0, name: "请选择礼包" });
  }

  //操作按钮
  private handleEdit(row: any): void {
    //数据赋值
    this.form = row;
    this.isCreate = false;

    //判断赋值
    if (row.gift_type == 1) {
      this.picUrl = null;
      this.picFileList = [];
    } else {
      if (row.logo_url != "") {
        this.picUrl = row.logo_url;
        this.picFileList = [{ url: row.logo_url }];
      } else {
        this.picUrl = null;
        this.picFileList = [];
      }
    }

    //显示界面
    this.dialogVisible = true;
  }

  //获取礼包名称
  private getGiftName(row: any): string {
    //道具名称
    if (row.gift_type == 1) {
      for (var i: number = 0; i < this.itemList.length; i++) {
        //判断返回
        if (this.itemList[i].propid == row.gift_id) {
          return this.itemList[i].propname;
        }
      }
    }
    //礼包名称
    else if (row.gift_type == 2) {
      for (var i: number = 0; i < this.giftList.length; i++) {
        //判断返回
        if (this.giftList[i].id == row.gift_id) {
          return this.giftList[i].name;
        }
      }
    }

    //返回空
    return "";
  }

  //------------------------- 添加/编辑奖励配置 ---------------------------
  //定义变量
  private dialogVisible = false;
  private getHeads = getHeaders();
  private getAction = this.getApi();
  private dialogLoading: boolean = false;

  //获取API
  private getApi() {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片变量
  private picUrl: any = null;
  private picFileList: any[] = [];
  private imgDialogVisible = false;
  private dialogImageUrl: any = null;

  //奖励类型
  private giftTypeList: any[] = [
    { id: 1, name: "道具" },
    { id: 2, name: "礼包" },
  ];

  //表单数据
  private form: any = {
    //数据赋值
    id: 0, //ID
    day: 1, // 天数
    gift_num: 1, //奖励数量
    gift_id: 0, //道具活奖励
    gift_type: 1, //奖励类型 1-道具 2-礼包
    logo_url: "", //奖励为礼包时的礼包 logo
  };

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //修改
  private typeChange(value: any): void {
    //清空数据
    this.form.gift_id = 0;
  }

  //保存礼包
  private async handlePush() {
    //判断返回
    if (this.form.gift_id == 0) return this.$message.error("请选择" + (this.form.gift_type == 1 ? "道具" : "礼包"));
    if (Number(this.form.day) == 0) return this.$message.error("天数 > 0");
    if (Number(this.form.gift_num) == 0) return this.$message.error("奖励数量 > 0");
    if (this.form.gift_type == 2 && this.picUrl == null) return this.$message.error("请添加礼包Logo");

    //数据赋值
    if (this.form.gift_type == 1) {
      this.form.logo_url = "";
    } else {
      this.form.logo_url = this.picUrl;
    }
    this.form.day = Number(this.form.day);
    this.form.gift_num = Number(this.form.gift_num);

    //编辑礼包
    const res = await saveSignAwardSet(this.form);

    //显示提示
    this.$message.success("编辑签到配置成功");

    //隐藏界面
    this.dialogVisible = false;

    //获取数据
    this.getList();
  }

  //上传
  private handlePicturePreview(file: any) {
    //数据赋值
    this.imgDialogVisible = true;
    this.dialogImageUrl = file.url;
  }

  //上传文件之前的钩子
  private handlePicturBeforeUpload(file: any) {
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出5M",
        type: "warning",
        duration: 2000,
      });
      return false;
    } else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图片
  private handlePicturRemove(file: any, fileList: any) {
    //清空数据
    this.picUrl = null;
    this.picFileList = [];
  }

  //上传成功
  private handlePicturUploadSuccess(response: any, file: any, fileList: any) {
    //数据赋值
    this.picUrl = response.data.images[0];
    this.picFileList = fileList;
  }
}
